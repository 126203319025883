import { Paper, Box, Typography } from '@mui/material';
import React from 'react';
import theme from '../../../style/Theme/theme';
import logo from '../../../imgs/GoodGames-Logo-PMS-Final.png';
import { Link } from 'react-router-dom';

type InvalidOrgInviteScreenProps = {
  setFormChoice(value: 'register' | 'login'): void;
};

const InvalidOrgInviteScreen = ({ setFormChoice }: InvalidOrgInviteScreenProps) => {
  return (
    <Box>
      <Paper sx={{ width: '100vw', height: '6.5vh' }}>
        <Box
          sx={{
            width: 228,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: { width: 'auto' },
          }}>
          <Box component='span' sx={{ display: { md: 'block' }, flexGrow: 1, margin: '10px 0px 0px 10px' }}>
            <img src={logo} aria-label='GoodGames Logo' width={100} />
          </Box>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px' }}>
        <Typography variant='h1' className='text-center'>
          This invite link is no longer available.
        </Typography>
        <Typography sx={{ width: '500px' }} variant='h5' color='black' className='text-center'>
          If you feel as though this was done in error, feel free to reach out to (support email that i can remember)
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <Typography variant='h4' color='black'>
            Head back to{' '}
            <Link to='/' onClick={() => setFormChoice('register')}>
              Signup
            </Link>{' '}
            OR{' '}
            <Link to='/' onClick={() => setFormChoice('login')}>
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InvalidOrgInviteScreen;
