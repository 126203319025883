import {CircularProgress} from "@mui/material"
import './loadingSpinner.scss'

export interface loadingSpinnerProps {
  size: 'sm' | 'm' | 'lg';
  text: string;
}

const LoadingSpinner = (props: loadingSpinnerProps) => {
  return (
    <div className='loading-spinner__container'>
      <CircularProgress
        size={
          props.size === 'sm' ? 25
            : props.size === 'm' ? 50
              : props.size === 'lg' ? 100
                : undefined
        }/>
      <h5
        className='pt-3'
        style={
          props.size === 'sm' ? {fontSize: '11px'}
            : props.size === 'm' ? {fontSize: '15px'}
              : props.size === 'lg' ? {fontSize: '20px'}
                : {}}
      >{props.text}...</h5>
    </div>
  )
}

export default LoadingSpinner
