import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Grid, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PASSWORD_RESET_RESPONSE from '../../../gql/mutations/general/passwordReset/passwordResetResponse';
import {
  passwordResetResponse,
  passwordResetResponseVariables,
} from '../../../gql/mutations/general/passwordReset/__generated__/passwordResetResponse';
import { resetPasswordSchema } from '../../../utils/validations/resetPassword';
import HomeWrapper from '../../home/HomeWrapper';

interface ResetPasswordParams {
  resetCode: string;
}

const ResetPassword = () => {
  const { resetCode } = useParams<keyof ResetPasswordParams>() as ResetPasswordParams;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);

  const navigate = useNavigate();

  const [resetHandler] = useMutation<passwordResetResponse, passwordResetResponseVariables>(PASSWORD_RESET_RESPONSE);

  const {
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    control,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = async (data: any) => {
    if (data.newPassword !== data.confirmPassword) return;

    const res = await resetHandler({ variables: { code: resetCode, password: data.newPassword } });

    if (res) {
      reset();
      navigate('/admin/dashbaord');
    }
  };

  const handleShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleShowConfirmPass = () => {
    setShowConfirmPass((showConfirmPass) => !showConfirmPass);
  };

  return (
    <>
      <HomeWrapper title='Reset Password'>
        <Grid container display='flex' justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name='newPassword'
                defaultValue={getValues('newPassword')}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      value={field.value}
                      onFocus={() => trigger('newPassword')}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger('newPassword');
                      }}
                      label='New Password'
                      className='mt-3'
                      error={errors.newPassword?.message ? true : false}
                      helperText={errors.newPassword?.message ?? null}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={handleShowPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='confirmPassword'
                defaultValue={getValues('confirmPassword')}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      value={field.value}
                      onFocus={() => trigger('confirmPassword')}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger('confirmPassword');
                      }}
                      label='Confirm New Password'
                      className='mt-4'
                      error={errors.confirmPassword?.message ? true : false}
                      helperText={errors.confirmPassword?.message ?? null}
                      type={showConfirmPass ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={handleShowConfirmPass}>
                              {showConfirmPass ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />
              <Button
                disabled={!isDirty}
                type='submit'
                color='primary'
                variant='contained'
                className='mt-3'
                size='large'
                fullWidth>
                Reset Password
              </Button>
            </form>
          </Grid>
        </Grid>
      </HomeWrapper>
    </>
  );
};

export default ResetPassword;
