import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import logo from '../../imgs/GoodGames-Logo-PMS-Final.png';
import theme from '../../style/Theme/theme';
import './HomeWrapper.scss';

type HomeWrapperProps = {
  title: string;
  link?: string;
  linkText?: string;
  children: JSX.Element;
};
const HomeWrapper = (props: HomeWrapperProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box width='100%' sx={{ height: props.title === 'Sign Up' ? '100%' : '100vh', position: 'relative' }}>
      <Box
        className='companyLogo'
        sx={{
          width: 228,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: { width: 'auto' },
        }}>
        <Box component='span' sx={{ display: { md: 'block' }, flexGrow: 1, margin: '10px 0px 0px 10px' }}>
          <img src={logo} aria-label='GoodGames Logo' width={200} />
        </Box>
      </Box>
      <Box className='px-5 pb-3 pt-1'>
        <Typography variant='h1' color='secondary' sx={{ maxWidth: '512px', margin: '20px auto 20px auto' }} width='100%'>
          {props.title}
        </Typography>
        <Paper
          className={'p-5'}
          elevation={5}
          style={{
            margin: '0 auto 0 auto',
            textAlign: 'center',
            maxWidth: '512px',
            height: '100%',
          }}>
          {props.children}
        </Paper>
      </Box>
    </Box>
  );
};

export default HomeWrapper;
