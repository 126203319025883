import { RecoilRoot } from 'recoil';
import { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import lazy from './utils/lazy';
const WithAuthentication = lazy(() => import('./components/adminComponents/auth/WithAuthentication'));
const AdminConsole = lazy(() => import('./screens/adminScreens/generalAdminScreens/AdminConsoleScreen'));
const Event = lazy(() => import('./screens/generalScreens/EventWaitScreen'));
const AsyncGame = lazy(() => import('./screens/gameScreens/AsyncGameMainScreen'));
const NotFound = lazy(() => import('./components/home/notFound/NotFound'));
const GameList = lazy(() => import('./components/generalComponents/ReportGameListComponent'));
const GameReport = lazy(() => import('./components/generalComponents/ReportGameReportComponent'));
const ArtistUpload = lazy(() => import('./screens/generalScreens/ArtistUploadScreen'));
const AdminManagement = lazy(() => import('./screens/adminScreens/generalAdminScreens/AdminManagementScreen'));
const OrgInvite = lazy(() => import('./screens/adminScreens/generalAdminScreens/OrgInviteScreen'));

import './style/typography.scss';
import './style/general.scss';
import './style/colors.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

import LoadingSpinner from './components/reusable/loadingSpinner/LoadingSpinner';
import { ThemeProvider } from '@mui/material';
// @ts-ignore
import theme from './style/Theme/theme.ts';
import ResetPassword from './components/adminComponents/auth/PasswordResetScreen';
import InvalidOrgInviteScreen from './screens/adminScreens/generalAdminScreens/InvalidOrgInviteScreen';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {useCookies} from 'react-cookie'

library.add(fas, far);

function App() {
  const [cookies, setCookie] = useCookies(['authToken'])
  const [token, setToken] = useState<string>('');
  const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
  const [formChoice, setFormChoice] = useState<'login' | 'register'>('login');

  useEffect(() => {
    // if there isn't any token available, then we'll need to get one first

    if (!cookies.authToken) {
      setTokenIsValid(false);
      return;
    }
    setTokenIsValid(true);
    setToken(token);
  }, [token]);

  const routes = [
    {
      path: '/admin/*',
      component: <AdminConsole />,
      access: 'admin',
    },
    {
      path: '/artist/:code',
      component: <ArtistUpload />,
      access: 'public',
    },
    {
      path: '/report/:eventCode/:gameId',
      component: <GameReport />,
      access: 'public',
    },
    {
      path: '/report/:eventCode',
      component: <GameList />,
      access: 'public',
    },
    {
      path: '/:eventCode/:asyncCode',
      component: <AsyncGame />,
      access: 'public',
    },
    {
      path: '/:eventCode',
      component: <Event />,
      access: 'public',
    },
    {
      path: '/',
      component: <AdminConsole />,
      access: 'admin',
    },
    {
      path: '/passreset/:resetCode',
      component: <ResetPassword />,
      access: 'public',
    },
    {
      path: '/org-invite/:inviteCode',
      component: <OrgInvite setFormChoice={setFormChoice} />,
      access: 'public',
    },
    {
      path: '/invalid-invite',
      component: <InvalidOrgInviteScreen setFormChoice={setFormChoice} />,
      access: 'public',
    },
    {
      path: '*',
      component: <NotFound />,
      access: 'public',
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <RecoilRoot>
          <Router>
            <Suspense fallback={<LoadingSpinner size={'lg'} text={'Loading...'} />}>
              <Routes>
                {routes.map((route, index) => {
                  if (route.access === 'admin') {
                    sessionStorage.setItem('aa', '1');
                    route.component = <>{route.component}</>;
                  }
                  return <Route key={index} path={route.path} element={route.component} />;
                })}
              </Routes>
            </Suspense>
          </Router>
        </RecoilRoot>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
