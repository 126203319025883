import React from 'react';
import { ComponentType, lazy } from 'react';
export default (resolver: any, name = 'default') => {
  return lazy(async () => {
    const resolved = await resolver();
    return { default: resolved[name] };
  });
};

export interface retryArgs {
  fn: () => Promise<{ default: ComponentType<any> }>;
  retriesLeft?: number;
  interval?: number;
}

export const retry = (fn: retryArgs['fn'], retriesLeft = 5, interval = 100): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: string) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const lazyLoad = (fn: retryArgs['fn']) => React.lazy(() => retry(fn));
