import { createTheme } from '@mui/material';
import '../colors.scss';
import '../typography.scss';

// ? Will we be supporting dark theme?

let theme = createTheme({
  palette: {
    primary: {
      main: '#006E9C',
      light: '#EFFAFF',
    },
    secondary: {
      main: '#4FA150',
      light: '#EDF5ED',
    },
    warning: {
      main: '#F28F1E',
      light: '#FFF4CC',
    },
    error: {
      dark: '#a30005',
      main: '#FF3A41',
      light: '#ffccce',
    },
    grey: {
      700: '#616161',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '2.125rem',
      fontWeight: 600,
      color: '#006E9C',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#006E9C',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#006E9C',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#006E9C',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: '#006E9C',
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: '#006E9C',
    },
    body1: {
      fontSize: '0.85rem',
      fontWeight: '200',
      color: '#525252',
    },
    caption: {
      color: '#757575',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          '&.Mui-selected': {
            background: '#EFFAFF',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'none'
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
  },
});

export default theme;
