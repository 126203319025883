import * as yup from 'yup';
import { passwordRegEx } from './regExTypes';

export interface RequestReset {
  email: string;
  newPassword?: string;
  confirmPassword?: string;
}

export interface ResetResponse {}

export const resetPasswordSchema = yup.object({
  email: yup.string().email(),
  newPassword: yup
    .string()
    .min(8, 'Your password should have a minimum of 8 characters.')
    .max(20, 'Your password should not exceed 20 characters in length.')
    .matches(passwordRegEx, {
      message: 'Your password should have at least 1 uppercase letter, 1 lowercase letter, and 1 number.',
    }),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords do not match.'),
});
