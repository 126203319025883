import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from './style/Theme/theme';
import Spaces from '@ably/spaces';
import { SpacesProvider, SpaceProvider } from '@ably/spaces/react';
import { Realtime } from 'ably';
import { AblyProvider } from 'ably/react';

const ablyKey = 'zv2QQg.OazWUA:LdqtOf_x1CMeiUbPXAjUwrvQTystjSgOM0T97WQIdUA';
const ably = new Realtime.Promise({ key: ablyKey, clientId: 'item-test', log: { level: 2 } });
const spaces = new Spaces(ably);

const httpLink = new HttpLink({
  uri: `https://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies if it exists
  const cookies = document.cookie.split(';');

  const tokenCookie = cookies.filter((cookie) => {
    const splitCookie = cookie.split('=');
    if (splitCookie[0] === ' authToken') {
      return true;
    }
  })[0];

  const token = tokenCookie?.split('=')[1];
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : '',
    },
  };
});

const authHttpLink = ApolloLink.from([authLink, httpLink]);

const wsLink = new WebSocketLink({
  uri: `wss://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authHttpLink
);

export const client = new ApolloClient({
  link: splitLink,
  connectToDevTools: true,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <AblyProvider client={ably}>
      <SpacesProvider client={spaces}>
        <SpaceProvider name='item-test'>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </SpaceProvider>
      </SpacesProvider>
    </AblyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
